.LogoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.EntryLogo {
  position: absolute;
  display: inline-block;
  width: 600px;
  height: 400px;
  top: 0;
  right: 50%;
  transform: translate(50%, -120%);
  opacity: 0.7;
  pointer-events: none;
}

.EntryLogoAnim {
  animation: entryLogoAnimation 3s ease-out forwards;
}

@keyframes entryLogoAnimation {
  0% {
    top: 0%;
    transform: translate(50%, -100%);
  }
  10% {
    top: 60%;
    transform: translate(50%, -50%);
  }
  15% {
    top: 58%;
  }
  40% {
    top: 59%;
  }
  100% {
    top: 50%;
    transform: translate(50%, -50%);
  }
}

.CommonEffect {
  position: absolute;
  display: inline-block;
  width: 600px;
  height: 400px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.HoverMeContainer {
  top: 17%;
  transform: translateY(-50%) rotateZ(45deg);
  left: 12%;
  position: absolute;
  width: 100px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #08fdd8;
}

.HoverMeShow {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

.HoverMeHide {
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.HoverText {
  font-size: 12px;
  text-align: center;
  opacity: 1;
  animation: hoverTextAnimation 5s ease-in-out infinite;
}

@keyframes hoverTextAnimation {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.2;
  }
  22% {
    opacity: 1;
  }
  24% {
    opacity: 0.2;
  }
  26% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  82% {
    opacity: 0.2;
  }
  94% {
    opacity: 0.2;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.IconsArrow {
  width: 100%;
}

.IconArrow {
  opacity: 0.2;
  width: 30%;
  display: inline-block;
  margin: 10px -9px 0 5px;
}

.IconArrowAnim1 {
  animation: iconArrowAnimation1 3s ease-in-out infinite;
}

.IconArrowAnim2 {
  animation: iconArrowAnimation2 3s ease-in-out infinite;
}

.IconArrowAnim3 {
  animation: iconArrowAnimation3 3s ease-in-out infinite;
}

@keyframes iconArrowAnimation1 {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  33% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes iconArrowAnimation2 {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.2;
  }
  33% {
    opacity: 1;
  }
  63% {
    opacity: 1;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes iconArrowAnimation3 {
  0% {
    opacity: 0.2;
  }
  63% {
    opacity: 0.2;
  }
  66% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.IconArrow img {
  width: 100%;
}


.Logo {
  position: absolute;
  width: 100%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  transition: all 0.3s ease-in-out;
}

.LogoLight {
  position: absolute;
  width: 100%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  transition: all 0.3s ease-in-out;

}

.LightningEffect {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  transition: all 0.3s ease-in-out;
  width: 160% !important;
  opacity: 0.7;
  pointer-events: none;
}

.SmokeEffectSoftLeft {
  position: absolute;

  width: 50% !important;
  bottom: 20% !important;
  left: 0;
  pointer-events: none;
  opacity: 0.2;
}

.SmokeEffectSoftRight {
  position: absolute;
  width: 50% !important;
  bottom: 20% !important;
  right: 0;
  pointer-events: none;
  opacity: 0.2;
transform: rotateY(180deg);
}


.SmokeEffectFullLeft {
  position: absolute;

  width: 50% !important;
  bottom: 20% !important;
  left: 0;
  pointer-events: none;
  opacity: 0.2;
}

.SmokeEffectFullRight {
  position: absolute;
  width: 50% !important;
  bottom: 20% !important;
  right: 0;
  pointer-events: none;
  opacity: 0.2;
  transform: rotateY(180deg);
}

.SmokeLanding {
  position: absolute;
  width: 80% !important;
  bottom: 20% !important;
  left: 53%;
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0.4;
}


.CommonEffectEntering {
  animation: commonEffectEntering 4s ease-in-out forwards;
  transition: all 0.3s ease-in-out;
}


.LogoEntering {
  animation: logoEntering 4s ease-out forwards;
}

.LightEffectEntering {
  animation: lightEffectEntering 4s ease-out forwards;
}

.CommonEffectEntered {
  animation: commonEffectEntered 8s linear infinite;
  transition: all 0.3s ease-in-out;
}


.LogoEntered {
  animation: logoEntered 8s ease-out infinite;
}

.LightEffectEntered {
  animation: lightEffectEntered 8s ease-out infinite;
}


.CommonEffectExiting {
  animation: commonEffectExiting 2s ease-out forwards;
  transition: all 0.3s ease-in-out;
}


.LogoExiting {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  animation: logoExiting 2s ease-out forwards;

}

.LightEffectExiting {
  opacity: 0.05;
  animation: logoEffectExiting 2s ease-out forwards;
  transition: all 0.3s ease-in-out;

}


.CommonEffectExited {
  animation: commonEffectExited 4s ease-out infinite;
  transition: all 0.3s ease-in-out;
}


.LogoExited {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.LightEffectExited {
  opacity: 0.05;
  animation: logoEffectExited 4s ease-out infinite;
  transition: all 0.3s ease-in-out;

}

@media (max-width: 1100px) {
  .CommonEffect {
    width: 400px;
    height: 300px;
    top: 50%;
    right: 45%;
  }


  .EntryLogo {
    width: 400px;
    height: 300px;
    right: 45%;
  }


  .HoverMeContainer {
    top: 28%;
    left: 18%;
    width: 80px;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    color: #08fdd8;
  }

  .SmokeLanding {
    left: 57%;
    bottom: 22% !important;
  }

  .SmokeEffectSoftLeft {
    width: 45% !important;
    bottom: 25% !important;
    left: 10%;
  }

  .SmokeEffectSoftRight {
    width: 45% !important;
    bottom: 25% !important;
    right: 0%;

  }


  .SmokeEffectFullLeft {
    width: 45% !important;
    bottom: 25% !important;
    left: 10%;
  }

  .SmokeEffectFullRight {
    width: 45% !important;
    bottom: 25% !important;
    right: 0%;

  }
}

@media (max-width: 960px) {
  .LogoContainer {
    display: none;
  }
}

@media (max-width: 500px) {
  .LogoContainer {
    display: none;
  }
}


@keyframes commonEffectEntering {
  0% {
    transform: translate(50%, -50%);
  }

  100% {
    opacity: 1;
    transform: translate(50%, -70%);
  }
}


@keyframes logoEntering {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lightEffectEntering {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}


@keyframes commonEffectEntered {
  0% {
    opacity: 1;
    transform: translate(50%, -70%);
  }
  1% {
    opacity: 0.2;
  }
  2% {
    opacity: 1;
  }
  3% {
    opacity: 0.2;
  }
  4% {
    opacity: 1;
  }
  31% {
    transform: translate(50%, -65%);
    opacity: 0.2;
  }
  32% {
    opacity: 1;
  }
  33% {
    opacity: 0.2;
  }
  34% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  71% {
    opacity: 0.2;
  }
  72% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(50%, -70%);
  }
}

@keyframes logoEntered {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0.2;
  }
  2% {
    opacity: 1;
  }
  3% {
    opacity: 0.2;
  }
  4% {
    opacity: 1;
  }
  31% {
    opacity: 0.2;
  }
  32% {
    opacity: 1;
  }
  33% {
    opacity: 0.2;
  }
  34% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  71% {
    opacity: 0.2;
  }
  72% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lightEffectEntered {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0.2;
  }
  2% {
    opacity: 1;
  }
  3% {
    opacity: 0.2;
  }
  4% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  31% {
    opacity: 0.2;
  }
  32% {
    opacity: 1;
  }
  33% {
    opacity: 0.2;
  }
  34% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  71% {
    opacity: 0.2;
  }
  72% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


@keyframes commonEffectExiting {

  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(50%, -50%);
  }
}

@keyframes logoExiting {
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes logoEffectExiting {

  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.05;
  }
}


@keyframes commonEffectExited {
  0% {
    opacity: 0.7;
    transform: translate(50%, -50%);
  }
  50% {
    opacity: 1;
    transform: translate(50%, -55%);
  }
  100% {
    opacity: 0.7;
    transform: translate(50%, -50%);
  }
}


@keyframes logoEffectExited {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.05;
  }
}

