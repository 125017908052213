a {
    text-decoration: none;
    color: inherit;
}

.ProjectContainer {
    position: relative;
    min-width: 200px;
    max-width: 1200px;
    width: 90%;
    margin: 30px auto;
    top: 0px;
    text-align: center;
    background-color: #171717;
    padding: 20px;
    border-radius: 2%;
    box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    cursor: pointer;
    animation: projectAnimation 2s ease-in-out;

}


@keyframes projectAnimation {
    0% {
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);

    }
    50% {
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);
    }
    100% {
        top: 0px;
        opacity: 1;
        box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);

    }
}

.ProjectContainerHover {
    background-color: #191919;
    box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, 0.4);
    top: -8px;
    transition: all .3s ease-in-out;

}

.ProjectContent {
    margin: 15px;
    position: relative;
    display: flex;
}

.TextZone {
    position: relative;
    width: 60%;
    transition: all 0.3s ease-in-out;
    text-align: justify;
    padding-left: 60px;
}

.GameName {
    color: #08fdd8;
    text-shadow: 0 0 11px rgb(8 253 216);
    font-weight: bold;
    font-size: 22px;
    padding: 0px 0 15px 0;
    height: 25px;
}

.Title {
    color: #08fdd8;
    font-weight: bold;
    font-size: 18px;
    /*padding: 30px 0 10px 0;*/
    height: 25px;
}

.TitleHover {
    color: #a9f5ed;
}

.Years {
    font-size: 14px;
    color: #6B6B6B;
    padding-bottom: 10px;
}

.Description {
    /*color: #666666;*/
    font-size: 14px;
}

.DescriptionHover {
    color: #888888;
}

.ImageZone {
    width: 40%;
}

.Thumbnail {
    height: 360px;
    border: #1f1f1f solid 1px;
    position: relative;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 400px;
    min-width: 360px;
}

.ThumbnailImg {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: width .3s ease-in-out;
}

.ThumbnailImgHover {
    width: 103%;
    transition: width .3s ease-in-out;
}


.PlayIconHoverBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: width .3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
}


.PlayIconHoverEntryAnimation {
    opacity: 1;
}


.PlayIconHoverExitingAnimation {
    opacity: 0;

}

.PlayIconHover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    transition: width .3s ease-in-out;
}

@media (max-width: 1100px) {
    .ProjectContainer {
        /*width: 25%;*/
        /*max-width: 250px;*/
        margin: 30px 15px;
    }

    .Thumbnail {
        height: 245px;
        width: 100%;
        max-width: 400px;
        min-width: 200px;
    }

    .GameName {
        font-size: 22px;
    }

    .Title {

        font-size: 18px;
    }

    .Description {
        font-size: 13px;
    }
}


@media (max-width: 960px) {
    .ProjectContainer {
        width: 70%;
        margin: 30px auto;
    }

    .ProjectContent {
        margin: 15px;
        position: relative;
        display: block;
    }
    .ImageZone {
        position: relative;
        width: 100%;
    }
    .Thumbnail {
        height: 356px;
        width: 100%;
        top:unset;
        transform: translate(-50%, 0);
        margin-bottom: 30px;
        min-width: 356px;
    }

    .TextZone {
        width: 100%;
        padding-left: 0;
    }

    .GameName {
        font-size: 20px;
    }
    .Title {
        font-size: 16px;
    }

    .Description {
        font-size: 13px;
    }
}


@media (max-width: 500px) {
    .ProjectContainer {
        width: 70%;
        margin: 30px auto;

    }

    .ProjectContent {
        margin: 15px;
        position: relative;
        display: block;
    }
    .ImageZone {
        position: relative;
        width: 100%;
    }
    .Thumbnail {
        height: 250px;
        width: 100%;
        top:unset;
        transform: translate(-50%, 0);
        margin-bottom: 30px;
        min-width: 250px;

    }

    .TextZone {
        width: 100%;
        padding-left: 0;
    }
    .GameName {
        font-size: 16px;
    }

    .Title {
        font-size: 14px;
    }

    .Years {
        font-size: 12px;
    }
    .Description {
        font-size: 12px;
    }
}