.AboutMeContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.TextZone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 30%;
    transition: all 0.3s ease-in-out;
    text-align: justify;
}

.ImgZone {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0%;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    animation: ImageZoneAnimation 4s ease-in forwards;
}


@keyframes ImageZoneAnimation {
    0% {
        opacity: 0;
    }
    85% {

        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ImgZone img {
    position: absolute;
    width: 850px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    transition: all 0.3s ease-in-out;
}



.TimelineImage {
    width: 100%;
    animation: timelineImageAnimation 4s ease-in forwards;
}

@keyframes timelineImageAnimation {
    0% {
        opacity: 0;
    }
    85% {

        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.TextZone h1 {
    color: #08fdd8;
    font-size: 65px;
    margin: 15px 0;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    animation: textZoneH1 1s ease-out forwards;
}


.TextZone h1:before {
    content: "<h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: absolute;
    left: -4%;
    top: -15px;
}

.TextZone h1:after {
    content: "</h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: absolute;
    left: -4%;
    top: 75px;
}


@keyframes textZoneH1 {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    50% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}


.P1 {
    animation: P1Animation 3s ease-out forwards;
}


@keyframes P1Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    50% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}


.P2 {
    animation: P2Animation 3s ease-out forwards;
}


@keyframes P2Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    62% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.P3 {
    animation: P3Animation 3s ease-out forwards;
}


@keyframes P3Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    74% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.P4 {
    animation: P4Animation 3s ease-out forwards;
}


@keyframes P4Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    86% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@media (max-width: 1600px) {
    .TextZone {
        width: 40%;
    }

    .ImgZone {
        width: 50%;
    }

    .ImgZone img {
        width: 550px;
    }
}

@media (max-width: 1100px) {
    .AboutMeContainer {
        padding-top: 60px;

    }

    .TextZone {
        width: 40%;
    }

    .ImgZone {
        width: 40%;
        right: 5%;
    }

    .ImgZone img {
        width: 450px;
    }

    .TextZone h1 {
        font-size: 55px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;

    }

    .TextZone h1:before {
        font-size: 12px;
        top: -15px;
    }

    .TextZone h1:after {
        font-size: 12px;
        top: 65px;
    }

}

@media (max-width: 960px) {
    .AboutMeContainer {
        display: flex;
        flex-direction: column;
    }

    .TextZone {
        position: relative;
        display: table-cell;
        transform: none;
        width: 80%;
        left: 10%;
        top: unset;
        margin-top: 30px;
    }

    .ImgZone {
        display: table-cell;
        position: relative;
        width: 80%;
        right: unset;
        top: unset;
        transform: none;
        margin: auto;
        padding-bottom: 80px;

    }


    .ImgZone img {
        position: relative;
        width: 450px;
        right: unset;
        top: unset;
        transform: none;
        margin: auto;
        display: block;
        margin: auto;
    }

    .TextZone h1 {
        font-size: 40px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;
    }

    .TextZone h1:before {
        font-size: 12px;
        top:-15px;
    }

    .TextZone h1:after {
        font-size: 12px;
        top: 45px;

    }

}

@media (max-width: 500px) {
    .AboutMeContainer {
        font-size: 14px;
    }

    .TextZone {
        margin-top: 5px;

    }

    .ImgZone {
        width: 100%;
        right: unset;
        padding-bottom: 0px;

    }

    .ImgZone img {
        width: 100%;
    }

    .TextZone h1 {
        font-size: 30px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;
    }

    .TextZone h1:before {
        font-size: 10px;
        left: -1%;
    }

    .TextZone h1:after {
        font-size: 10px;
        top: 42px;
        left: -1%;
    }

}