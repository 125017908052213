.HtmlTopTag {
    position: absolute;
    top: 30px;
     left: 80px;
}

.BodyTopTag {
    top: 50px;
    left: 90px;
    position: absolute;
}

.HtmlBottomTag {
    position: absolute;
    left: 80px;
    bottom: 30px;
}

.BodyBottomTag {
    position: absolute;
    left: 90px;
    bottom: 50px;
}

@media (max-width: 960px ) {

    .HtmlTopTag {
        top: 30px;
        left: 30px;
    }

    .BodyTopTag {
        top: 50px;
        left: 40px;
    }

    .HtmlBottomTag {
        left: 30px;
        bottom: 30px;
    }

    .BodyBottomTag {
        left: 40px;
        bottom: 50px;
    }
}

@media (max-width: 500px ) {

    .HtmlTopTag {
        top: 10px;
        left: 10px;
    }

    .BodyTopTag {
        top: 30px;
        left: 20px;
    }

    .HtmlBottomTag {
        left: 10px;
        bottom: 10px;
    }

    .BodyBottomTag {
        left: 20px;
        bottom: 30px;
    }
}