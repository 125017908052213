.ProgressBarsContainer {
    width: 100%;
    left: 0%;


}

@media (max-width: 1100px) {

    /*.ProgressBarsContainer {*/
    /*    position: relative;*/
    /*    width: 100%;*/
    /*    left: unset;*/
    /*    top: unset;*/
    /*    transform: none;*/
    /*}*/
}

@media (max-width: 960px) {

    .ProgressBarsContainer {
        position: relative;
        width: 100%;
        left: unset;
        top: unset;
        transform: none;

    }
}


@media (max-width: 500px) {

    .ProgressBarsContainer {
        position: relative;
        width: 80%;
        left: 10%;
        margin-top: 30px;
        margin-bottom: 70px;
    }
}
