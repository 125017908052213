.ProjectContainer {
    position: relative;
    display: inline-block;

    min-width: 200px;
    width: 25%;
    max-width: 250px;
    margin: 30px 15px;
    top: 0px;
    text-align: center;
    background-color: #171717;
    padding: 20px;
    border-radius: 2%;
    box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    cursor: pointer;
    animation: projectAnimation 2s ease-in-out ;

}


@keyframes projectAnimation {
    0% {
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);

    }
    50%{
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);
    }
    100% {
        top: 0px;
        opacity: 1;
        box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);

    }
}

.ProjectContainerHover {
    background-color: #191919;
    box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, 0.4);
    top: -8px;
    transition: all .3s ease-in-out;

}

.Thumbnail {
    height: 200px;
    width: 100%;
    border: #1f1f1f solid 1px;
    position: relative;
    overflow: hidden;
}

.ThumbnailImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: width .3s ease-in-out;
}

.ThumbnailImgHover {
    width: 103%;
    transition: width .3s ease-in-out;

}


.Title {
    color: #08fdd8;
    font-weight: bold;
    font-size: 18px;
    padding: 30px 0 10px 0;
    height: 25px;
}

.TitleHover {
    color: #a9f5ed;
}

.Description {
    color: #666666;
    font-size: 14px;
}

.DescriptionHover {
    color: #888888;
}

@media (max-width: 1600px) {
    .ProjectContainer {
        width: 25%;
        max-width: 250px;
        margin: 30px 15px;
    }

    .Thumbnail {
        height: 200px;
        width: 100%;
    }
}

@media (max-width: 1100px) {
    .ProjectContainer {
        width: 25%;
        max-width: 250px;
        margin: 30px 15px;
    }

    .Thumbnail {
        height: 200px;
        width: 100%;
    }


    .Title {

        font-size: 18px;
    }

    .Description {
        font-size: 13px;
    }
}


@media (max-width: 960px) {
    .ProjectContainer {
        width: 20%;
        max-width: 250px;
        min-width: 150px;
        margin: 30px 15px;
    }

    .Thumbnail {
        height: 150px;
        width: 100%;
    }


    .Title {
        font-size: 16px;
    }

    .Description {
        font-size: 13px;
    }
}



@media (max-width: 500px) {
    .ProjectContainer {
        width: 15%;
        height: 140px;
padding: 10px;
        max-width: 250px;
        min-width: 100px;
        margin: 10px 15px;
    }

    .Thumbnail {
        height: 100px;
        width: 100%;
    }


    .Title {
        font-size: 12px;
        overflow: hidden;
        padding: 15px 0 10px 0;
    }

    .Description {
        font-size: 13px;
    }
}