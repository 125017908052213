.NavigationItems {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
    align-items: center;
}


@media (max-width: 960px) {
    .NavigationItems {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}