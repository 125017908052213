.ProjectModalContainer {
    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.85);
    z-index: 505;
    height: 100%;
    width: 100%;
    opacity: 1;

}

.ProjectModalContainerOpen {
    animation: projectModalOpenAnimation .3s ease-out forwards;
}

@keyframes projectModalOpenAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.ProjectModalContainerClose {
    animation: projectModalCloseAnimation .3s ease-out forwards;
}

@keyframes projectModalCloseAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.Backdrop {
    height: 100%;
    width: 100%;
}

.Visualizer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: 80%;
}

.Visualizer img {
    width: 100%;
}

.LeftBtn {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%) scale(1);
    background-color: #131313;
    border: 1px solid #131313;
    border-radius: 5px;
    transition: all .3s ease-in-out;
    height: 90px;
    width: 70px;
    opacity: 0.5;
    cursor: pointer;
}

.LeftBtn:hover {
    opacity: 1;
    transform: translateY(-50%) scale(1.1);
}

.LeftBtnBar1 {
    height: 25%;
    width: 2px;
    background-color: #08fdd8;
    border: 2px solid #08fdd8;
    border-radius: 5px;
    Z-index: 1;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.LeftBtnBar2 {
    height: 25%;
    width: 2px;
    background-color: #08fdd8;
    border: 2px solid #08fdd8;
    border-radius: 5px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
    Z-index: 2;
}

.RightBtn {
    position: absolute;
    top: 50%;
    right: 2%;
    background-color: #131313;
    border: 1px solid #131313;
    border-radius: 5px;
    transform: translateY(-50%) scale(1);
    transition: all .3s ease-in-out;
    height: 90px;
    width: 70px;
    opacity: 0.5;
    cursor: pointer;
}

.RightBtn:hover {
    opacity: 1;
    transform: translateY(-50%) scale(1.1);
}

.RightBtnBar1 {
    height: 25%;
    width: 2px;
    background-color: #08fdd8;
    border: 2px solid #08fdd8;
    border-radius: 5px;
    Z-index: 1;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
}

.RightBtnBar2 {
    height: 25%;
    width: 2px;
    background-color: #08fdd8;
    border: 2px solid #08fdd8;
    border-radius: 5px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    Z-index: 2;
}


.CloseBtnContainer:hover {
    transform: scale(1.15);
    transition: all .1s ease-in-out;
    opacity: 1;

}


.LinkZone {
    position: absolute;
    height: 50px;
    line-height: 50px;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    text-align: center;
    background-color: #08fdd8;
    opacity: 0.7;
    text-underline: none;
}

.LinkZone:hover {
    background-color: #08fdd8;
    cursor: pointer;
    opacity: 1;

}

.Link {
    color: black;
    font-weight: bold;
    text-underline: none;
    text-decoration: none;
}


.CloseBtnContainer {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50px;
    right: 50px;
    transition: all .1s ease-in-out;
    transform: scale(1);
    opacity: 0.7;
    cursor: pointer;
}

.CloseBtnBar1 {
    height: 100%;
    width: 2px;
    background-color: #08fdd8;
    border: 2px solid #08fdd8;
    border-radius: 5px;
    Z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.CloseBtnBar2 {
    height: 100%;
    width: 2px;
    background-color: #08fdd8;
    border: 2px solid #08fdd8;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
    Z-index: 2;
}


.CloseBtnContainer:hover {
    transform: scale(1.15);
    transition: all .1s ease-in-out;
    opacity: 1;

}


@media (max-width: 960px) {


    .Visualizer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-height: 80%;
    }

    .LeftBtn {
        height: 60px;
        width: 40px;
    }

    .RightBtn {
        height: 60px;
        width: 40px;
    }
}