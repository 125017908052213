.loader,
.loader:after {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.loader {
    margin: 10px auto;
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(8, 253, 216, 0.2);
    border-right: 1.1em solid rgba(8, 253, 216, 0.2);
    border-bottom: 1.1em solid rgba(8, 253, 216, 0.2);
    border-left: 1.1em solid #08fdd8;
    animation: load8 1.1s infinite linear;
}
@media (max-width: 1100px) {

    .loader {
        font-size: 5px;
    }
}

@media (max-width: 500px) {
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 15px;
        height: 15px;
    }
    .loader {
        margin: 7px auto;
        font-size: 5px;
    }
}


@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
