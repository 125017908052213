.Toggle {
    height: 10px;
    width: 25px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    position: absolute;
    top: 15px;
    cursor: pointer;
}

.Toggle div {
    height: 2px;
    width: 85%;
    background-color: #08fdd8;
}