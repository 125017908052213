.Container {
    width: auto;
    border: 0;
    background: #2b2b2b;
    min-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    outline: none;
}

.FileButton {
    background-color: #1d1d1d;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    font: inherit;
    font-size: 14px;
    padding: 10px;
    margin: 15px 0px;
    transition: all .3s ease-in-out;
    background-color: transparent;
    border: #08fdd8 solid 2px;
    color: #08fdd8;
    display: block;
}

.ImagesUploaderContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Placeholder {
    width: 1px;
    height: 100%;
    border: 2px solid #08fdd8;
    /* transition: all 0.2s ease-in-out; */
    position: absolute;
    left: -12px;
}

.PlaceholderEnd {
    width: 1px;
    border: 2px solid #08fdd8;
}