.Button {
    background-color: #1d1d1d;
    border: none;
    color: white;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    font: inherit;
    font-size: 14px;
    padding: 10px;
    margin: 15px 0px;
    transition: all .3s ease-in-out;
}

.Button:disabled,
.Button:disabled:hover{
    background-color: #565656;
    border:  #565656  solid 2px;
    color: #cccccc;
    cursor: not-allowed;
}

.Normal {
    background-color: transparent;
    border:  #08fdd8 solid 2px;
    color: #08fdd8;
}
.Normal:hover {
    background-color: #08fdd8;
    border:  #08fdd8  solid 2px;
    color: #1d1d1d;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
}