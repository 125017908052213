.SkillsContainer {
    width: 100%;
    height: 100%;
    position: relative;
}


.Navlink {
    color: #08fdd8;
    text-decoration: none;
}

.Navlink:hover {
    color: #9bfff3;
    text-decoration: underline solid #9bfff3 ;
}

.TextZone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 30%;
    transition: all 0.3s ease-in-out;
    text-align: justify;
}

.SkillsBarContainer {
    position: absolute;
    width: 50%;
    right: 0;
    top: 23%;
    overflow: hidden;
}

.TextZone h1 {
    color: #08fdd8;
    font-size: 65px;
    margin: 15px 0;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    text-align: left;
    animation: textZoneH1 1s ease-in forwards;

}

.TextZone h1:before {
    content: "<h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: absolute;
    left: -4%;
    top: -3px;
}

.TextZone h1:after {
    content: "</h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: relative;
    margin-left: 10px;
    bottom: 0px;
}

@keyframes textZoneH1 {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    50% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}


.P1 {
    animation: P1Animation 1.5s ease-out forwards;
}


@keyframes P1Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
   75% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}


.P2 {
    animation: P2Animation 2s ease-out forwards;
}


@keyframes P2Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    80% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.P3 {
    animation: P3Animation 2.5s ease-out forwards;
}


@keyframes P3Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    85% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.P4 {
    animation: P4Animation 3s ease-out forwards;
}


@keyframes P4Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    87% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}



@media (max-width: 1600px) {


    .SkillsBarContainer {
        width: 50%;
    }

}

@media (max-width: 1100px) {
    .SkillsContainer {
        padding-top: 60px;
        padding-bottom: 60px;

    }
    .TextZone {
        width: 40%;
    }

    .SkillsBarContainer {
        width: 40%;
        right: 5%;
    }

    .TextZone h1 {
        font-size: 55px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;

    }

    .TextZone h1:before {
        font-size: 12px;
        top: -10px;
    }

    .TextZone h1:after {
        font-size: 12px;
        position: relative;
        margin-left: 10px;
        bottom: 0px;
    }

}

@media (max-width: 960px) {
    .SkillsContainer {
        display: flex;
        flex-direction: column;
    }

    .TextZone {
        position: relative;
        display: table-cell;
        transform: none;
        width: 80%;
        left: 10%;
        top: unset;
        margin-top: 30px;
    }

    .SkillsBarContainer {
        display: table-cell;
        position: relative;
        width: 80%;
        right: unset;
        top: unset;
        transform: none;
        margin: auto;
        padding-bottom: 80px;

    }

    .TextZone h1 {
        font-size: 40px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;
    }

    .TextZone h1:before {
        font-size: 12px;
    }

    .TextZone h1:after {
        font-size: 12px;
        position: relative;
        margin-left: 10px;
        bottom: 0px;

    }

}

@media (max-width: 500px) {
    .SkillsContainer {
        font-size: 14px;
    }

    .TextZone {
        margin-top: 5px;

    }

    .SkillsBarContainer {
        width: 100%;
        right: unset;
        padding-bottom: 0px;

    }

    .TextZone h1 {
        font-size: 30px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;
    }

    .TextZone h1:before {
        font-size: 12px;
    }

    .TextZone h1:after {
        font-size: 12px;
        position: relative;
        margin-left: 10px;
        bottom: 0px;

    }

}