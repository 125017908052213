.Container {
    width: 100%;
    height: 100%;
    margin-top: 0;
    position: relative;
    display: flex;
    flex-direction: column;
}

.ContainerElement {
    width: auto;
}

.ElementRight {
    text-align: right;
}


.TablesColumns {
    display: flex;
    flex-direction: row;
    color: #08fdd8;
    font-size: 16px;

}

.SkillOrderPrio {
    margin-left: 0;
}

.SkillTitle {
    margin-left: 80px;
}

.SkillLevel {
    margin-left: 100px;
}


.TextZone {
    position: relative;
    top: 5%;
    /* transform: translateY(-50%); */
    width: 60%;
    transition: all 0.3s ease-in-out;
    /* text-align: justify; */
}

.TextZone h1 {
    color: #08fdd8;
    font-size: 35px;
    margin: 15px 0;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    animation: textZoneH1 1s ease-out forwards;

}

.TextZone h1:before {
    content: "<h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: absolute;
    left: -10%;
    top: -3px;
}

.TextZone h1:after {
    content: "</h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: relative;
    margin-left: 10px;
    bottom: 0px;
}

@keyframes textZoneH1 {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    50% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}


.TextZone p {
    padding: 30px 0;
}


.P1 {
    animation: P1Animation 1.5s ease-out forwards;
}


.ImageIcon {
    width: 20px;
    height: 20px;
}

.ImageZone {
    width: 50px;
    height: 50px;
    padding-left: 15px;
}

.Thumbnail {
    width: 50px;
    height: 50px;
    border: #1f1f1f solid 1px;
    overflow: hidden;
}

.ThumbnailImg {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: width .3s ease-in-out;
}



@keyframes P1Animation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    50% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.ContactForm {
    width: 100%;
    padding-bottom: 150px;
}

.ContactForm ul {
    padding: 0;
    margin: 0;
}

.ContactForm ul li.Half:first-child {
    margin-left: 0;
}

.ContactForm ul li label.LabelName {
    font-family: 'Dancing Script', cursive;
    color: #08fdd8;
    text-align: left;
}

.ContactForm ul {
    padding: 0;
    margin: 0;
}

.ContactForm ul li.Half:first-child {
    margin-left: 0;
}

.ContactForm ul li {
    padding: 0;
    margin: 20px 0 10px 0;
    list-style: none;
    /*opacity: 0;*/
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
}

.ContactForm ul li.Half {
    width: 49%;
    float: left;
    clear: none;
}

.ContactFormLI1Animation {
    transform: translateY(0%) rotateZ(0deg);
    animation: contactFormLI1Animation ease-in-out 4s forwards;
}

@keyframes contactFormLI1Animation {
    0% {
        opacity: 0;
        transform: translateY(200%) rotateZ(40deg);
    }

    40% {
        opacity: 0;
        transform: translateY(200%) rotateZ(40deg);
    }

    60% {
        opacity: 1;
        transform: translateY(0%) rotateZ(0deg);
    }

    100% {

        transform: translateY(0%) rotateZ(0deg);
    }
}

.ContactFormLI2Animation {
    transform: translateY(0%) rotateZ(0deg);
    animation: contactFormLI2Animation ease-in-out 4s forwards;
}

@keyframes contactFormLI2Animation {
    0% {
        opacity: 0;
        transform: translateY(200%) rotateZ(20deg);
    }

    50% {
        opacity: 0;
        transform: translateY(200%) rotateZ(-20deg);
    }

    70% {
        opacity: 1;
        transform: translateY(0%) rotateZ(0deg);
    }

    100% {

        transform: translateY(0%) rotateZ(0deg);
    }
}

.ContactFormLI3Animation {
    transform: translateY(0%) rotateZ(0deg);
    animation: contactFormLI3Animation ease-in-out 4s forwards;
}

@keyframes contactFormLI3Animation {
    0% {
        opacity: 0;
        transform: translateY(200%) rotateZ(10deg);
    }

    55% {
        opacity: 0;
        transform: translateY(200%) rotateZ(10deg);
    }

    75% {
        opacity: 1;
        transform: translateY(0%) rotateZ(0deg);
    }

    100% {

        transform: translateY(0%) rotateZ(0deg);
    }
}

.ContactFormLI4Animation {
    transform: translateY(0%) rotateZ(0deg);
    animation: contactFormLI4Animation ease-in-out 4s forwards;
}

@keyframes contactFormLI4Animation {
    0% {
        opacity: 0;
        transform: translateY(50%) rotateZ(40deg);
    }

    60% {
        opacity: 0;
        transform: translateY(50%) rotateZ(-20deg);
    }

    85% {
        opacity: 1;
        transform: translateY(0%) rotateZ(0deg);
    }

    100% {

        transform: translateY(0%) rotateZ(0deg);
    }
}

.ContactForm input[type="text"],
.ContactForm input[type="email"],
.ContactForm input[type="password"]
 {
    width: 100%;
    background: #2b2b2b;
    border: 0;
    height: 50px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
    outline: none;
}


.ContactForm textarea,
.ContactForm textarea:focus {
    width: 100%;
    border: 0;
    background: #2b2b2b;
    height: 50px;
    font-size: 16px;
    color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    outline: none;
}


.ButtonAnimation {
    opacity: 0;
    transform: scale(0.7) rotate(-30deg);
    animation: buttonAnimation 4s ease-in-out forwards;
}

@keyframes buttonAnimation {
    0% {
        opacity: 0;
        transform: scale(0.7) rotate(-30deg);
    }

    80% {
        opacity: 0;
        transform: scale(0.7) rotate(-30deg);
    }

    100% {
        opacity: 1;
        transform: scaleY(1) rotate(0);
        ;
    }
}

.LabelFeedBack {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 2px;
    transition: width .4s ease-in-out;
}

.Valid {
    width: 100%;
    background-color: #08fdd8;
}

.Error {
    width: 100%;
    color: goldenrod;
}

@media (max-width: 960px) {
    .ContactMeContainer {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }

    .TextZone {
        position: relative;
        display: table-cell;
        transform: none;
        width: 80%;
        left: 10%;
        top: unset;
        margin-top: 30px;
    }


    .TextZone h1 {
        font-size: 32px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;

    }

    .TextZone h1:before {
        font-size: 12px;
        top: -10px;
    }

    .TextZone h1:after {
        font-size: 12px;
        position: relative;
        margin-left: 10px;
        bottom: 0px;
    }

    .SkillOrderPrio {
        margin-left: 0;
    }
    
    .SkillTitle {
        margin-left: 80px;
    }
    
    .SkillLevel {
        margin-left: 50px;
    }
}

@media (max-width: 500px) {
    .ContactMeContainer {
        padding-top: 30px;
    }

    .TextZone h1 {
        font-size: 25px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;
    }

    .TextZone h1:before {
        font-size: 12px;
        top: -15px;
    }

    .TextZone h1:after {
        font-size: 12px;
        position: relative;
        margin-left: 10px;
        bottom: 0px;

    }

    .TablesColumns {
        font-size: 12px;
    }

    .TextZone p {
        padding: 0px 0 15px 0;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    .SkillOrderPrio {
        margin-left: 0;
    }
    
    .SkillTitle {
        margin-left: 60px;
    }
    
    .SkillLevel {
        margin-left: 20px;
    }
}