.FeedbackMessageContainer {
    display: flex;
    flex-flow: row;
    top: 50px;
    right: 0;
    width: 105%;
    height: 70px;
    background-color: #2b2b2b;
    border: #2b2b2b solid 2px;
    border-radius: 5px;
    margin: 15px 0;
    transition: all .3s ease-in-out;
    opacity: 0;
    transform: translateX(100%);
    border-left: #08fdd8 solid 5px;
}

.IconZone {
    display: inline-block;
    width: 20%;
    margin-left: 5%;
}

.Icon {
    position: relative;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.TextZone {
    display: inline-block;
    width: 70%;
    margin-left: 5%;
}

.Text {

    font-size: 16px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.OpenAnim {
    animation: openAnimation 0.5s ease-out forwards;
}

.CloseAnim {
    animation: closeAnimation 0.5s ease-in backwards;
}

@media (max-width: 1100px) {
    .FeedbackMessageContainer {
        height: 60px;
        margin: 10px 0;

    }
    .Text {

        font-size: 14px;

    }
}

@media (max-width: 500px) {
    .FeedbackMessageContainer {
        height: 40px;
        margin: 5px 0;

    }
    .Text {

        font-size: 12px;

    }
}

@keyframes openAnimation {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
        transform: translateX(0%);
    }
    100% {
        transform: translateX(5%);
        opacity: 1;
    }
}


@keyframes closeAnimation {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }
    20% {
        opacity: 1;
        transform: translateX(5%);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

