.HomeContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.TextZone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    transition: all 0.3s ease-in-out;

}


.TextZoneAnim {
    animation: textZone 2s ease-in-out infinite;
}

@keyframes textZone {
    0% {
        left: 10%;
        transform: translateY(-50%) scale3d(1, 1, 1);
    }
    50% {
        left: 9.8%;
        transform: translateY(-50%) scale3d(0.99, 1.01, 1);
    }
    100% {
        left: 10%;
        transform: translateY(-50%) scale3d(1, 1, 1);
    }
}

.LogoZone {
    position: absolute;
    width: 60%;
    height: 100%;
    right: 0%;
    top: 50%;
    transform: translateY(-50%);
}

.TextZone h1 {
    font-size: 65px;
    margin: 15px 0;
    display: inline-block;
    opacity: 0;
    animation: textZoneH1 1s ease-in forwards;
}

.TextZone h1:before {
    content: "<h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: absolute;
    left: -4%;
    top: -3%;
}

.TextZone h1:after {
    content: "</h1>";
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 14px;
    position: absolute;
    margin-left: 45px;
    margin-top: 45px;
    transform: translateX(-100%);

}


@keyframes textZoneH1 {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    50% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.TextZone h2 {
    color: #797979;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    padding-bottom: 25px;
    transform: translateX(-100%);
    animation: textZoneH2 1.5s ease-in forwards;
}


@keyframes textZoneH2 {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    50% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.ZCharacter {
    display: inline-block;
    animation: zCharAnim 3s ease-in forwards;
}


@keyframes zCharAnim {
    0% {
        opacity: 0;
        transform: scaleY(1.5);
    }
    10% {
        transform: scaleY(2);
    }
    50% {
        transform: scaleY(0.7);
    }
    100% {
        transform: scaleY(1);
    }
}


.ButtonAnimation {
    position: absolute;
    opacity: 0;
    transform: scale(0.7) rotate(-30deg);
    animation: buttonAnimation 2s ease-in-out forwards;
}

@keyframes buttonAnimation {
    0% {
        opacity: 0;
        transform: scale(0.7) rotate(-30deg);
    }
    80% {
        opacity: 0;
        transform: scale(0.7) rotate(-30deg);
    }
    100% {
        opacity: 1;
        transform: scaleY(1) rotate(0);;
    }
}

.ButtonHomeEffect {
    opacity: 1;
    transform: rotate(0);
    transition: all 0.3s ease-in;
    animation: buttonHomeEffect .3s ease-in-out forwards;
}


@keyframes buttonHomeEffect {
    0% {
        transform: rotate(0);

    }

    100% {
        transform: rotate(-30deg);

    }
}

@media (max-width: 1100px) {
    .TextZone h1 {
        font-size: 55px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;
    }

    .TextZone h1:before {
        font-size: 12px;
    }

    .TextZone h1:after {
        font-size: 12px;
        margin-left: 45px;
        margin-top: 40px;
    }

    .TextZone h2 {
        font-size: 14px;
        transition: all 0.3s ease-in-out;

    }
}

@media (max-width: 960px) {
    .HomeContainer {
        height: 90vh;
    }

    .TextZone {
        width: 80%;
        left: 10%;
    }

}

@media (max-width: 500px) {
    .HomeContainer {
        height: 90vh;
    }

    .TextZone {

        top: 45%;
        transform: translateY(-50%);

    }

    .TextZone h1 {
        font-size: 40px;
        margin: 15px 0;
        transition: all 0.3s ease-in-out;
    }

    .TextZone h1:before {
        font-size: 12px;
        left: -4%;
        top: -10%;
    }

    .TextZone h1:after {
        font-size: 12px;
        margin-left: 38px;
        margin-top: 25px;
    }
    .TextZone h2 {
        font-size: 12px;
        transition: all 0.3s ease-in-out;
    }
}