.ProgressBarContainer {
    width: 40%;
    position: relative;
    display: inline-block;
    margin: 15px 15px;
    color: #ffffff;
    transform: translateX(-10%);
    opacity: 0;
    animation: barAnimation 0.8s ease-out forwards;
}

@keyframes barAnimation {
    0% {
        transform: translateX(-10%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.LeftSection {
    width: 20%;
    display: inline-block;
}

.RightSection {
    height: 50px;
    max-width: 260px;

    width: 80%;
    display: inline-block;
    position: relative;
}

.Icon {
    height: 50px;
    width: 50px;
}

.Icon img {
    width: 100%;
}

.SkillName {
    font-size: 16px;
    position: absolute;
    top: 0;
}

.Value {
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 6%;
    color: #676767;
}

.FillBarBackground {
    position: absolute;
    bottom: 0;
    height: 23px;
    width: 100%;
    max-width: 250px;
    background-color: #313131;
    border-radius: 150px;
    border-top: 1px solid #6B6B6B;
    border-right: 1px solid #6B6B6B;
    /*animation: aa 2s ease-in-out forward;*/
}

.FillBar {
    position: relative;
    margin-top: 1px;
    margin-left: 3px;
    height: 19px;
    background-image: linear-gradient(to left, #08fdd8, #606060);
    border-radius: 150px;
    transition: all 2s ease-in-out;
}

@media (max-width: 1100px) {

    .ProgressBarContainer {
        margin: 15px 15px;
    }

    .SkillName {
        font-size: 13px;
    }

    .Icon {
        height: 30px;
        width: 30px;
    }

}

@media (max-width: 500px) {

    .ProgressBarContainer {
        margin: 5px 10px;
    }

    .SkillName {
        font-size: 13px;
        top: 5px
    }

    .Icon {
        height: 25px;
        width: 25px;
        margin-right: 5px;
    }

    .LeftSection {
        width: 20%;
    }

    .RightSection {
        margin-left: 5%;
        width: 75%;
    }

    .Value {
        display: none;
    }
}
