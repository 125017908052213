.Tag {
    font-family: 'Dancing Script', cursive;
    color: rgba(180, 180, 180, 0.5);
    font-style: italic;
    letter-spacing: 2px;
    font-size: 16px;
}


@media (max-width: 960px ) {
    .Tag {
        font-size: 14px;
    }
}

@media (max-width: 500px ) {
    .Tag {
        font-size: 14px;
    }
}