.Toolbar {
    z-index: 500;
    width: 60px;
    height: 100%;
    background: #171717;
    position: fixed;
    display: flex;
    flex-flow: column;
}

.Toolbar nav {
    height: 100%;
    position: relative;
}

.DesktopOnly {
    display: block;
}

.MobileOnly {
    display: none;
}


@media (max-width: 960px) {
    .Toolbar {
        width: 100%;
        height: 60px;
        flex-flow: row;
        position: relative;


    }

    .Toolbar nav {
        height: 100%;
        position: initial;
    }
}

@media (max-width: 500px ) {
    .Toolbar {
        width: 100%;
        height: 60px;
        position: relative;
    }

    .DesktopOnly {
        display: none;
    }

    .MobileOnly {
        display: block;
    }

    .ToggleMobile {
        position: absolute;
        right: 15px;
        width: 26px;
        height: 60px;
    }

    .ToolbarMobileOnly {
        position: relative;
        height: 60px;
        overflow: hidden;
    }
}
