.TagSioud {
    width: 85px;
    animation: tagAnimation 10s linear;
    position: fixed;
    right: 60px;
    bottom: 30px;
}

.TagSioud img{
   width: 100%;
}

@keyframes tagAnimation {
    0% {

    }

    70% {

    }

    100% {

    }

}

@media (max-width: 500px) {
    .TagSioud {
        width: 45px;
        right: 30px;
        bottom: 20px;
    }
}