.LogoMin {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-top: 20px;
    animation: logoMinEffect 4s ease-out infinite;

}

.LogoMin img {
    margin: 2px auto;
}

.LogoMinImg {
    width: 50px;
}

.UnderLogo {
    width: 50px;
}

@media (max-width: 960px) {
    .LogoMin {
        width: 50px;
        display: flex;
        flex-flow: column;
        margin-top: 12px;
        margin-left: 15px;
        animation: logoMinEffect 4s ease-out infinite;

    }
    .UnderLogo {
        display: none;
    }
}

@media (max-width: 500px ) {
    .LogoMin {
        width: 50px;
        margin: auto;
        height: 100%;
    }

    .LogoMin img {
        margin: auto;
    }

    .UnderLogo {
        display: none;
    }

    .LogoMin-img {
        width: auto;
    }
}

@keyframes logoMinEffect {
    0% {
        opacity: 0.3;
        transform: translateY(0);
    }
    70% {
        opacity: 1;
        transform: translateY(-5px);
    }
    100% {
        opacity: 0.3;
        transform: translateY(0);
    }
}