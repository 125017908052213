a {
    text-decoration: none;
    color: inherit;
}

.Container {
    position: relative;
    margin: 15px auto;
    top: 0px;
    text-align: center;
    background-color: #171717;
    padding: 5px 15px;
    border-radius: 2%;
    box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);
    transition: all .2s ease-in-out;
}

.Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Data {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-align: left;
}


.ContainerHover {
    background-color: #191919;
    box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, 0.4);
    top: -2px;
    transition: all .1s ease-in-out;

}

.TextZone {
    position: relative;
    width: 60%;
    transition: all 0.3s ease-in-out;
    text-align: justify;
    padding-left: 60px;
}

.Name {
    color: #08fdd8;
    text-shadow: 0 0 11px rgb(8 253 216);
    font-weight: bold;
    font-size: 22px;
    padding: 0px 0 15px 0;
    height: 25px;
}

.Title {
    color: #08fdd8;
    font-weight: bold;
    font-size: 18px;
    /*padding: 30px 0 10px 0;*/
    height: 25px;
}

.TitleHover {
    color: #a9f5ed;
}

.Column0 {
    padding-left: 5px;
    width: 10px;
}

.Column1 {
    padding-left: 30px;
    width: 150px;
}

.Column2 {
    padding-left: 0px;
    width: 80px;
}


.ButtonsSection {
    display: flex;
    align-items: center;
    text-align: right;
}

.IconButtonEdit {
    padding: 3px 7px;
    margin-left: 15px;
    background-color: #08fdd8;
    border: #1f1f1f solid 1px;
    border-radius: 5px;
    cursor: pointer;

}

.IconButtonDelete {
    padding: 4px 7px;
    margin-left: 15px;
    background-color: #c51717;
    border: #1f1f1f solid 1px;
    border-radius: 5px;
    cursor: pointer;

}

.ImageIcon {
    width: 20px;
    height: 20px;
}

.ImageZone {
    width: 50px;
    height: 50px;
    padding-left: 15px;
}

.Thumbnail {
    width: 50px;
    height: 50px;
    border: #1f1f1f solid 1px;
    overflow: hidden;
}

.ThumbnailImg {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: width .3s ease-in-out;
}


@keyframes projectAnimation {
    0% {
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);

    }

    50% {
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);
    }

    100% {
        top: 0px;
        opacity: 1;
        box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);

    }
}

@media (max-width: 1100px) {

    .Name {
        font-size: 22px;
    }

    .Title {

        font-size: 18px;
    }
}


@media (max-width: 960px) {
    .Container {
        margin: 30px auto;
    }

    .Name {
        font-size: 20px;
    }

    .Title {
        font-size: 16px;
    }


    .Column0 {
        padding-left: 5px;
        width: 10px;
    }

    .Column1 {
        padding-left: 30px;
        width: 100px;
    }

    .Column2 {
        padding-left: 15px;
        width: 15px;
    }
}


@media (max-width: 500px) {
    .Container {
        margin: 30px auto;
    }

    .Data{
        font-size: 12px;
    }

    .Name {
        font-size: 20px;
    }

    .Title {
        font-size: 16px;
    }


    .Column0 {
        padding-left: 5px;
        width: 10px;
    }

    .Column1 {
        padding-left: 15px;
        width: 50px;
    }

    .Column2 {
        padding-left: 30px;
        width: 15px;
    }
    .ImageZone {
        width: 35px;
        height: 35px;
        padding-left: 15px;
    }
    
    .Thumbnail {
        width: 35px;
        height: 35px;
        border: #1f1f1f solid 1px;
        overflow: hidden;
    }
    
    .ThumbnailImg {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        transition: width .3s ease-in-out;
    }
}