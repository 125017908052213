.TransitionScreen {
    z-index: 501;
    position: fixed;
    top: 0;
    background: #181818;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
}

.Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 150px;
}

.Logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
}


.Logo img {
    width: 100%;
}

.LoadingSection {
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
    background: #033c35;
}

.LoadingBar {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    width: 0;
    height: 100%;
    background: #08fdd8;
    transition: all 0.1s ease-out;

}

.PlayLoadingAnimation {
    animation: loadingBarAnimation 3000ms ease-in-out;
}

@keyframes loadingBarAnimation {
    0% {
        width: 0;
    }
    20% {
        width: 0;
        height: 100%;
    }
    70% {
        width: 100%;
        height: 100%;
        background: #08fdd8;
    }
    74%{
        height: 200%;
        background: #fcfffe;
    }
    90% {


    }
    100% {
        height: 100%;
        background: #08fdd8;
        width: 100%;
    }
}

.PlayAnimation {
    animation: playAnimation 3000ms ease-in-out;
}

@keyframes playAnimation {
    0% {
        transform: translateX(-100%);
    }
    20% {
        transform: translateX(0%);
    }
    80% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

@media (max-width: 1100px) {

    .Content {
        width: 200px;
        height: 100px;

    }

    .Logo {
        width: 100px;
        height: 100px;
    }


}


@media (max-width: 500px) {

    .Content {
        width: 120px;
        height: 70px;

    }

    .Logo {
        width: 70px;
        height: 70px;
    }


}