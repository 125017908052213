.NavigationItemsMobile {
    height: 60px;
    width: 100%;
    background: #171717;
    display: flex;
    flex-flow: row;
    align-items: center;
    z-index: 500;
    position: relative;
}

.Open {
    transform: translateX(0);
    opacity: 1;
    transition: all 0.3s ease-in-out;

}

.Close {
    transform: translateX(100%);
    opacity: 0;
    transition: all 0.3s ease-in-out;

}

