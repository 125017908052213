.ProjectsList {
    position: relative;
    width: 50%;
    /*align-items: center;*/
    /*justify-items: center;*/
    /*text-justify: auto;*/
    text-align: center;
margin-top: 40px;
    display: inline-block;
}


.PlayEntryAnimation {
    animation: projectAnimation 1s ease-in-out ;
}
@keyframes projectAnimation {
    0% {
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);

    }
    50%{
        top: -20px;
        opacity: 0;
        box-shadow: 20px 20px 15px 25px rgba(0, 0, 0, 0.6);
    }
    100% {
        top: 0px;
        opacity: 1;
        box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);

    }
}