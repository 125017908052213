.FeedbackMessagesContainer {
    display: flex;
    flex-flow: column;
    position: fixed;
    top:50px;
    right:0 ;
    width: 20%;
    overflow: hidden;
}

@media (max-width: 1100px) {
    .FeedbackMessagesContainer {

        width: 25%;
    }
    }

@media (max-width: 960px) {
    .FeedbackMessagesContainer {

        width: 25%;
    }
}

@media (max-width: 500px) {
    .FeedbackMessagesContainer {
        top:70px;
        width: 150px;
    }
}


