.Layout {
    width: 100%;
    height: 100%;
    position: absolute;
}

.Container {
    width: 100%;
    height: 100%;
    min-height: 566px;
    position: absolute;
}

.Content {
    color: aliceblue;
    height: 100%;
    width: 100%;
    position: relative;
}

@media (max-width: 960px ) {
    .Layout {
        width: 100%;
        height: auto;
        /*position: relative;*/
    }

    .Container {
        margin-top: 0px;
        margin-left: 0px;
        position: relative;
    }
}

@media (max-width: 500px ) {
    .Container {
        margin-top: -60px;
        margin-left: 0px;

    }
}