.MyWorkContainer {
    width: 100%;
    height: auto;
    position: relative;

}


.SectionSelection {
    position: relative;
    text-align: center;
    width: 350px;
    margin: auto;

}


.SectionBtn {
    position: relative;
    top: 0;
    color: #474747;
    font-size: 40px;
    font-weight: bold;
    transition: all .3s ease-in-out;
    margin: 15px 30px;
    text-shadow: 2px 2px 6px rgb(0, 0, 0);
    cursor: pointer;
}


.SectionSelectionBtnLeft {
    left: 0;
    position: relative;
    animation: sectionSelectionBtnLeftAnimation 2.5s ease-in-out forwards;
}

@keyframes sectionSelectionBtnLeftAnimation {
    0% {
        opacity: 0;
        left: -100px;
    }
    20% {
        opacity: 0;
        left: -100px;
    }
    22% {
        opacity: 1;
    }
    40% {
        left: 30px;
    }

    80% {
        left: -20px;
    }
    100% {
        left: 0;
    }
}

.SectionSelectionBtnRight {
    right: 0;
    position: relative;
    animation: sectionSelectionBtnRightAnimation 2.5s ease-in-out forwards;
}

@keyframes sectionSelectionBtnRightAnimation {
    0% {
        opacity: 0;
        right: -100px;
    }
    20% {
        opacity: 0;
        right: -100px;
    }
    22% {
        opacity: 1;
    }
    40% {
        right: 30px;
    }

    80% {
        right: -20px;
    }
    100% {
        right: 0;
    }
}

.SectionBtnActive {
    color: #08fdd8;
    top: 2px;
    transition: all .1s ease-in-out;
    text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    animation: flickerLightAnimation 8s linear infinite;
}

@keyframes flickerLightAnimation {
    0% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }
    20% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }
    30% {
        color: #08fdd8;
        text-shadow: 0 0 40px rgba(8, 253, 216, 1);
    }
    31% {
        color: #474747;
        text-shadow: 2px 2px 6px rgb(0, 0, 0);
    }
    39% {
        color: #474747;
        text-shadow: 2px 2px 6px rgb(0, 0, 0);
    }

    40% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }
    41% {
        color: #474747;
        text-shadow: 2px 2px 6px rgb(0, 0, 0);
    }
    42% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }
    100% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }
}


.UnderlineActiveBtn {
    position: relative;
    height: 1px;
    width: 43%;
    left: 30px;
    background-color: #08fdd8;
    border: #08fdd8 solid 2px;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    box-shadow: 0px 0px 30px 1px rgba(8, 253, 216, 1);
    animation: flickerLightUnderlineAnimation 3s linear forwards;
}


@keyframes flickerLightUnderlineAnimation {
    0% {
        opacity: 0;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.UnderlineActiveBtnRight {
    width: 23%;
    left: 245px;
}


.ProjectsListContainer {
    position: relative;
    left: 10%;
    width: 80%;
    transition: all 0.3s ease-in-out;
    text-align: justify;
    padding-top: 100px;
    overflow: hidden;

}

.SectionsContainer {
    position: relative;
    height: 100%;
    width: 200%;
    display: flex;
}

.SectionContainerLeftOpen {
    animation: sectionContainerLeftOpenAnimation 1s ease-out ;
}


@keyframes sectionContainerLeftOpenAnimation {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}


.SectionContainerLeftClose {
    animation: sectionContainerLeftCloseAnimation 1s ease-out forwards;
}


@keyframes sectionContainerLeftCloseAnimation {
    0% {
        opacity: 1;
        transform: translateX(0%);

    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}


.SectionContainerRightOpen {

    animation: sectionContainerRightOpenAnimation 1s ease-out forwards;
}


@keyframes sectionContainerRightOpenAnimation {
    0% {
        opacity: 0;
        transform: translateX(0%);
    }
    100% {
        opacity: 1;
        transform: translateX(-100%);
    }
}


.SectionContainerRightClose {
    animation: sectionContainerRightCloseAnimation 1s ease-out ;
}


@keyframes sectionContainerRightCloseAnimation {
    0% {
        opacity: 1;
        transform: translateX(-100%);

    }
    100% {
        opacity: 0;
        transform: translateX(0%);
    }
}


@media (max-width: 1100px) {

    .ProjectsListContainer {
        position: relative;
        left: 10%;
        width: 80%;
        transition: all 0.3s ease-in-out;
        text-align: justify;
    }


    .SectionSelection {
        width: 250px;
    }


    .SectionBtn {
        font-size: 30px;
        margin: 15px 15px;
    }

    .UnderlineActiveBtn {
        left: 22px;
        animation: flickerLightUnderlineAnimation 3s linear forwards;
    }

    .UnderlineActiveBtnRight {
        width: 23%;
        left: 167px;
    }

    @keyframes sectionSelectionBtnLeftAnimation {
        0% {
            opacity: 0;
            left: -100px;
        }
        20% {
            opacity: 0;
            left: -100px;
        }
        22% {
            opacity: 1;
        }
        40% {
            left: 15px;
        }

        80% {
            left: -20px;
        }
        100% {
            left: 0;
        }
    }

    @keyframes sectionSelectionBtnRightAnimation {
        0% {
            opacity: 0;
            right: -100px;
        }
        20% {
            opacity: 0;
            right: -100px;
        }
        22% {
            opacity: 1;
        }
        40% {
            right: 15px;
        }

        80% {
            right: -20px;
        }
        100% {
            right: 0;
        }
    }

}


@media (max-width: 960px) {
    .MyWorkContainer {
        padding-top: 70px;
        padding-bottom: 100px;
    }

    .ProjectsListContainer {
        position: relative;
        left: 0;
        width: 100%;
        transition: all 0.3s ease-in-out;
        text-align: justify;
        padding-top: 0;
    }

    .SectionSelection {
        width: 210px;
    }

    .SectionBtn {
        font-size: 25px;
    }

    .UnderlineActiveBtn {
        left: 16px;
        animation: flickerLightUnderlineAnimation 3s linear forwards;
    }

    .UnderlineActiveBtnRight {
        width: 23%;
        left: 142px;
    }
}


@media (max-width: 500px) {
    .MyWorkContainer {
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .ProjectsListContainer {
        position: relative;
        left: 0;
        width: 100%;
        transition: all 0.3s ease-in-out;
        text-align: justify;
    }


    .SectionBtn {
        font-size: 20px;
        margin: 15px 15px;
    }

    .UnderlineActiveBtn {
        width: 35%;
        left: 30px;
        animation: flickerLightUnderlineAnimation 3s linear forwards;
    }

    .UnderlineActiveBtnRight {
        width: 23%;
        left: 132px;
    }

    @keyframes sectionSelectionBtnLeftAnimation {
        0% {
            opacity: 0;
            left: -100px;
        }
        20% {
            opacity: 0;
            left: -100px;
        }
        22% {
            opacity: 1;
        }
        40% {
            left: 15px;
        }

        80% {
            left: -20px;
        }
        100% {
            left: 0;
        }
    }

    @keyframes sectionSelectionBtnRightAnimation {
        0% {
            opacity: 0;
            right: -100px;
        }
        20% {
            opacity: 0;
            right: -100px;
        }
        22% {
            opacity: 1;
        }
        40% {
            right: 15px;
        }

        80% {
            right: -20px;
        }
        100% {
            right: 0;
        }
    }
}