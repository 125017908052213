.NavigationItem {
    height: 25px;
    width: 100%;
    padding: 35px 0px;
    filter: grayscale(100%);
    text-align: center;
    transition: all 0.3s linear;
}

.NavigationItem p {
    font-size: 10px;
    color: #08fdd8;
    text-decoration: none;
    width: 100%;
    transition: all 0.3s linear;
    filter: grayscale(0%);
    margin: 0px;
}

a {
    text-underline: none;
}

a.active {
    filter: grayscale(0%);
}

.LinkEnter {
    animation: linkEnter 0.2s ease-in-out forwards;
    visibility: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LinkExit {
    animation: linkExit 0.2s ease-in-out forwards;
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes linkEnter {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;

    }
}

@keyframes linkExit {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

