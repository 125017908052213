.SectionSelection {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 12%;
    justify-content: center;
    align-items: center;

}

.SectionBtn {
    position: relative;
    top: 0;
    color: #474747;
    font-size: 35px;
    font-weight: bold;
    transition: all .3s ease-in-out;
    margin-right: 15px;
    text-shadow: 2px 2px 6px rgb(0, 0, 0);
    cursor: pointer;
}


.SectionSelectionBtn0 {
    left: 0;
    position: relative;
}

.SectionSelectionBtn1 {
    right: 0;
    position: relative;
}




.SectionBtnActive {
    color: #08fdd8;
    top: 2px;
    transition: all .1s ease-in-out;
    text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    animation: flickerLightAnimation 8s linear infinite;
}

@keyframes flickerLightAnimation {
    0% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }

    20% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }

    30% {
        color: #08fdd8;
        text-shadow: 0 0 40px rgba(8, 253, 216, 1);
    }

    31% {
        color: #474747;
        text-shadow: 2px 2px 6px rgb(0, 0, 0);
    }

    39% {
        color: #474747;
        text-shadow: 2px 2px 6px rgb(0, 0, 0);
    }

    40% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }

    41% {
        color: #474747;
        text-shadow: 2px 2px 6px rgb(0, 0, 0);
    }

    42% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }

    100% {
        color: #08fdd8;
        text-shadow: 0 0 20px rgba(8, 253, 216, 1);
    }
}


.UnderlineActiveBtn {
    position: relative;
    height: 1px;
    width: 132px;
    left: 0px;
    background-color: #08fdd8;
    border: #08fdd8 solid 2px;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    box-shadow: 0px 0px 30px 1px rgba(8, 253, 216, 1);
    animation: flickerLightUnderlineAnimation 1s linear forwards;
}


@keyframes flickerLightUnderlineAnimation {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.UnderlineActiveBtn1 {
    width: 70px;
    left: 150px;
}

.SectionsContainer {
    position: relative;
    height: 100%;
    width: 200%;
    display: flex;
}

.SectionContainerLeftOpen {
    animation: sectionContainerLeftOpenAnimation 1s ease-out;
}


@keyframes sectionContainerLeftOpenAnimation {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}


.SectionContainerLeftClose {
    animation: sectionContainerLeftCloseAnimation 1s ease-out forwards;
}


@keyframes sectionContainerLeftCloseAnimation {
    0% {
        opacity: 1;
        transform: translateX(0%);

    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}


.SectionContainerRightOpen {

    animation: sectionContainerRightOpenAnimation 1s ease-out forwards;
}


@keyframes sectionContainerRightOpenAnimation {
    0% {
        opacity: 0;
        transform: translateX(0%);
    }

    100% {
        opacity: 1;
        transform: translateX(-100%);
    }
}


.SectionContainerRightClose {
    animation: sectionContainerRightCloseAnimation 1s ease-out;
}


@keyframes sectionContainerRightCloseAnimation {
    0% {
        opacity: 1;
        transform: translateX(-100%);

    }

    100% {
        opacity: 0;
        transform: translateX(0%);
    }
}


@media (max-width: 1100px) {

    .SectionBtn {
        font-size: 30px;
        margin-right: 15px;
    }

    .UnderlineActiveBtn {
        left: 0;
        width: 112px;
        animation: flickerLightUnderlineAnimation 3s linear forwards;
    }

    .UnderlineActiveBtn1 {
        width: 60px;
        left: 130px;
    }

}


@media (max-width: 960px) {

    .SectionBtn {
        font-size: 30px;
        margin-right: 15px;
    }

    .UnderlineActiveBtn {
        left: 0;
        width: 112px;
        animation: flickerLightUnderlineAnimation 3s linear forwards;
    }

    .UnderlineActiveBtn1 {
        width: 60px;
        left: 130px;
    }
}


@media (max-width: 500px) {

    .SectionBtn {
        font-size: 16px;
        margin-right: 15px;
    }

    .UnderlineActiveBtn {
        left: 0;
        width: 58px;
        animation: flickerLightUnderlineAnimation 3s linear forwards;
    }

    .UnderlineActiveBtn1 {
        width: 34px;
        left: 75px;
    }
}