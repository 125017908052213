.ImageWrapper {
    padding-right: 20px;
    position: relative;
}

.UploadedImage {
    height: 100px;
    width: 150px;
    margin: 15px 0px;
    border: #444444 solid 2px;
}

.ButtonIconDelete {
    position: absolute;
    height: 20px;
    width: 20px;
    right: 25px;
    top: 21px;
    background-color: #c51717;
    border: #1f1f1f solid 1px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

.IconDelete {
    position: relative;
    height: 15px;
    width: 15px;
}