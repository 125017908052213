
.fade-appear,
.fade-enter {
    opacity: 0!important;
}

.fade-enter.fade-enter-active {
    opacity: 0!important;
    /*transition: all 0.3ms ease-in-out;*/

    /*animation: waitingAnimation 2000ms forwards;*/
}

@keyframes waitingAnimation {
    0% {
        opacity: 0!important;
        transform: scale(0.90);
    }
    50% {
        opacity: 0.8!important;
        transform: scale(0.90);
    }
    100% {
        opacity: 0.8!important;
        transform: scale(0.90);
    }
}

.fade-enter-done {
    animation: enterAnimation 1000ms forwards;
}

@keyframes enterAnimation {
    0% {
        opacity: 0;
        transform: scale(0.90);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.fade-exit {

}

.fade-exit.fade-exit-active {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    animation: exitAnimation 300ms forwards;
}

@keyframes exitAnimation {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0.8;
        transform: scale(0.9);
    }
}
